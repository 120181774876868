import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  updateReplicationGroup(event) {
    event.preventDefault();

    const product_plan_indexes = [...document.querySelectorAll("select.product_plan_select")]
      .map((s) => s.id.match(/attributes_(\d+)_foreign_key/)?.[1])
      .join(",");

    get(
      `/admin/fleet_programs/available_pos_external_plans?replication_group_id=${event.target.value}&product_plan_indexes=${product_plan_indexes}`,
      { responseKind: "turbo-stream" }
    );
  }

  addAnother(event) {
    event.preventDefault();

    const index = Math.max(
      ...[...document.querySelectorAll("select.product_plan_select")].map((s) =>
        parseInt(s.id.match(/attributes_(\d+)_foreign_key/)?.[1])
      )
    );
    const replication_group_id = event.target.dataset.replicationGroupId;

    get(
      `/admin/fleet_programs/new_product?replication_group_id=${replication_group_id}&index=${index}`,
      { responseKind: "turbo-stream" }
    );
  }

  deleteProduct(event) {
    event.preventDefault();

    const index = event.target.dataset.index;

    if (event.target.dataset.id) {
      document.getElementById(
        `fleet_program_fleet_program_checkout_form_attributes_fleet_products_attributes_${index}__destroy`
      ).value = "1";
      document.getElementById(`product_${index}`).classList.add("is-hidden");
    } else {
      // not saved, just remove it from dom
      document.getElementById(`product_${index}`).remove();
    }
  }

  copyCheckoutLink(event) {
    event.preventDefault();
    navigator.clipboard.writeText(event.target.dataset.link);
  }
}
