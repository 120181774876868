import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";
import { track } from "../tracking";
import { setupRecaptcha } from "helpers/recaptcha";

export default class extends Controller {
  static outlets = ["payment-update"];
  static targets = [
    "checkoutFormId",
    "total",
    "subtotal",
    "quantity",
    "firstName",
    "lastName",
    "email",
    "phone",
    "licensePlate",
    "locationSelect",
    "totalWithTax",
    "taxEstimate",
    "tax",
    "parentUrl",
    "product",
    "anonymousId",
    "slowMessage",
    "checkoutSuccess",
    "checkoutError",
    "recaptchaInput",
    "recaptchaDiv",
  ];

  static values = {
    products: String,
    taxForSalesEnabled: Boolean,
    recaptchaEnabled: Boolean,
    recaptchaSiteKey: String,
  };

  connect() {
    console.log("Connecting to checkout_forms Stimulus controller...");
    this.estimateTax();
    this.debouncedEstimateTax = debounce(this.estimateTax.bind(this), 1500);
    this.debouncedTrackEmailEntered = debounce(this.trackEmailEntered.bind(this), 500);
    this.setAnonymousId();
    this.paymentUpdateOutlet.onFormSubmit(this.startSlowMessageCountdown.bind(this));
    this.invalidateQuantityInputs();
    if (this.recaptchaEnabledValue) {
      console.log("ReCAPTCHA is enabled - connecting...");
      this.setupRecaptcha();
    }
  }

  setupRecaptcha() {
    console.log("Calling setupRecaptcha in checkout_forms Stimulus controller");

    const executeRecaptcha = setupRecaptcha(this.recaptchaDivTarget, this.recaptchaSiteKeyValue);
    this.paymentUpdateOutlet.addAsyncSubmitInterrupt(async () => {
      console.log("Adding ReCAPTCHA submit interrupt...");
      this.recaptchaInputTarget.value = await executeRecaptcha();
      return true;
    });
  }

  invalidateQuantityInputs() {
    if (this.hasQuantityTarget) {
      this.quantityTargets[0].setCustomValidity("At least one product is required.");
    }
  }

  checkoutSuccessTargetConnected(target) {
    const parent = target.dataset.parent;
    const url = target.dataset.url;

    if (parent && parent !== "false") {
      window.top.location.href = url;
    } else {
      window.location.href = url;
    }
  }

  checkoutErrorTargetConnected(target) {
    this.cancelSlowMessage();
    this.hideSlowMessage();
    this.paymentUpdateOutlet.hideSpinner();
    this.paymentUpdateOutlet.enableSubmit();
  }

  startSlowMessageCountdown() {
    if (!this.slowMessageTimeoutId) {
      this.slowMessageTimeoutId = setTimeout(() => {
        this.slowMessageTarget.hidden = false;
      }, 15000); // 15 seconds
    }
  }

  cancelSlowMessage() {
    if (this.slowMessageTimeoutId) {
      clearTimeout(this.slowMessageTimeoutId);
      this.slowMessageTimeoutId = null;
    }
  }

  hideSlowMessage() {
    this.slowMessageTarget.hidden = true;
  }

  setAnonymousId() {
    this.anonymousIdTarget.value = document.querySelector("body").dataset.anonymous_id;
  }

  updateTotal(event) {
    let total = 0;

    this.quantityTargets.forEach((quantityTarget) => {
      const quantity = parseInt(quantityTarget.value) || 0;
      const price = parseFloat(quantityTarget.dataset.price) || 0;
      total += (quantity * price) / 100;
    });

    this.totalTarget.textContent = total.toFixed(2);
  }

  checkQuantities(event) {
    if (this.quantityTargets.some((input) => parseInt(input.value ?? 0) > 0)) {
      this.quantityTargets[0].setCustomValidity("");
    } else {
      this.quantityTargets[0].setCustomValidity("At least one product is required.");
    }
  }

  getSelectedProductId() {
    for (const product of this.productTargets) {
      if (product.checked) {
        return product.value;
      }
    }
    return null;
  }

  async estimateTax() {
    if (!this.taxForSalesEnabledValue) {
      return;
    }

    let selectedProductId = this.getSelectedProductId();

    if (this.shouldEstimateTax(selectedProductId)) {
      const { subtotal, tax, total } = await this.fetchTaxEstimate({
        customer_email: this.emailTarget.value,
        customer_first_name: this.firstNameTarget.value,
        customer_last_name: this.lastNameTarget.value,
        customer_phone: this.phoneTarget.value,
        membership_license_plate: this.hasLicensePlateTarget ? this.licensePlateTarget.value : "",
        product_id: selectedProductId,
        wash_connect_site_id: this.locationSelectTarget.value,
      });

      this.subtotalTarget.textContent = subtotal;
      this.totalWithTaxTarget.textContent = total;
      this.taxTarget.textContent = tax;

      this.taxEstimateTarget.hidden = false;
    } else {
      this.taxEstimateTarget.hidden = true;
    }
  }

  async trackEmailEntered() {
    const payload = {
      checkout_form_id: this.checkoutFormIdTarget.value,
      email: this.emailTarget.value,
      first_name: this.firstNameTarget.value,
      last_name: this.lastNameTarget.value,
      parent_url: this.parentUrlTarget.value,
      phone: this.phoneTarget.value,
      product_id: this.getSelectedProductId(),
    };

    track("Email entered in checkout form", payload);
  }

  shouldEstimateTax(selectedProductId) {
    return (
      selectedProductId &&
      selectedProductId !== "" &&
      this.emailTarget.value !== "" &&
      this.firstNameTarget.value !== "" &&
      this.lastNameTarget.value !== "" &&
      this.phoneTarget.value !== "" &&
      this.locationSelectTarget.value !== ""
    );
  }

  async fetchTaxEstimate({
    customer_email,
    customer_first_name,
    customer_last_name,
    customer_phone,
    membership_license_plate,
    product_id,
    wash_connect_site_id,
  }) {
    const response = await fetch("/taxes/estimate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_email,
        customer_first_name,
        customer_last_name,
        customer_phone,
        membership_license_plate,
        product_id: product_id,
        wash_connect_site_id,
      }),
    });

    const taxEstimate = await response.json();
    return taxEstimate;
  }
}
